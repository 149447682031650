import { breakpoint, header } from '../utils/variables';
import { get_max_value } from '../utils/functions';

class MegaMenu {

    constructor() {
        this.nav        = $('.header-navigation');
        this.menu       = $('.header-megamenu');
        this.items      = this.nav.find('.menu-item');
        this.ul         = this.nav.find('ul');
        this.submenu    = this.nav.find('.sub-menu');
        this.subitems   = this.submenu.find('.menu-item');
        this.background = $('[data-megamenu-background]');
        this.trigger = {
            item:       '.header-megamenu li > *:not(.sub-menu)',
            link:       '.header-megamenu li.menu-item-has-children > a',
            submenu:    '[data-js-submenu-trigger]',
            back:       '[data-js-menu-back-trigger="true"]',
            burger:     '.js-burger-menu',
            search:     '.header-search-trigger',
            logo:       '.logo-wrap',
            toolbar:    '.toolbar-trigger',
        };
    }

    init() {

        const self = this;

        // self.set_submenu_top_offset();
        self.add_submenu_title();
        self.add_menu_back_trigger();
        self.add_data_submenu_height();

        $(document)
            .on('keydown', (e) => {
                if (e.key === 'Escape') {
                    self.reset();
                    self.submenu_mobile_reset();
                }
            })
            .on('mouseenter focus', self.trigger.item, (e) => {
                self.show_submenu(e);
                self.update_background_height(e);
            })
            .on('mouseenter', `${header.toolbar}, ${header.logo}, ${header.search}`, () => {
                self.reset();
            })
            .on('mouseleave', header.wrapper, () => {
                self.reset();
            })
            .on('focus', self.trigger.toolbar, () => {
                if( $(window).width() >= breakpoint.desktop ) self.reset();
            })
            .on('focus', `${self.trigger.logo}, ${self.trigger.search}`, () => {
                // console.log(e, header.isResponsive);
                if( $(window).width() >= breakpoint.desktop ) self.reset();
                // else self.submenu_mobile_close();
            })
            /* burger menu button */
            .on('click', self.trigger.burger, (e) => self.open_mobile_menu(e))

            .on('click', self.trigger.submenu, (e) => self.submenu_mobile_forward(e))
            .on('click', self.trigger.link, (e) => self.submenu_mobile_forward(e))

            .on('click', self.trigger.back, (e) => self.submenu_mobile_backward(e));
    }

    reset() {

        if(window.innerWidth < breakpoint.desktop) return false;

        const self = this;

        self.remove_active_class(self.items);
        self.reduce_background_menu();
    }

    show_submenu(e) {

        if(window.innerWidth < breakpoint.desktop) return false;

        const self = this;
        const $this = $(e.currentTarget);

        const parent = {
            current: $this.parent(),
            siblings: $this.parent().siblings(),
            // submenu: $this.closest('.sub-menu'),
        };

        const submenu = {
            next: $this.siblings('.sub-menu'),
        }

        self.remove_active_class(parent.siblings);

        if (submenu.next.length > 0) self.add_active_class(parent.current)

        // if (submenu.next.length > 0 && parent.submenu.length > 0) self.add_active_class(parent.submenu)
        // else self.remove_active_class(parent.submenu);

        if (!parent.current.hasClass('active')) self.active_ancestor_item(e);
    }

    open_mobile_menu(e) {

        const self = this;
        const item = {
            triggers: document.querySelectorAll('.header-megamenu a'),
        };

        var expanded = e.currentTarget.getAttribute('aria-expanded');

        if (expanded === 'true') {
            e.currentTarget.setAttribute('aria-expanded', 'false');
            document.body.classList.remove('mobile-menu-is-open');
            self.submenu_mobile_reset();
        }
        else {
            e.currentTarget.setAttribute('aria-expanded', 'true');
            document.body.classList.add('mobile-menu-is-open');
        }

        setTimeout(() => {
            item.triggers[0].focus();
        }, 400);
    }

    submenu_mobile_forward(e) {

        const self = this;
        const $this = $(e.currentTarget);

        const parent = {
            lists:      $this.parents('ul'),
            current:    $this.parent(),
            siblings:   $this.parent().siblings(),
        };

        const submenu = {
            next: $this.siblings('.sub-menu'),
        }

        ///e.preventDefault();

        self.remove_active_class(parent.siblings);

        if (submenu.next.length > 0) self.add_active_class(parent.current)
        if (!parent.current.hasClass('active')) self.active_ancestor_item(e);

        // self.submenu.removeClass('active');
        submenu.next.addClass('active').show();
        parent.lists.addClass('active');

        parent.current.addClass('backward').removeClass('forward');
        parent.siblings.addClass('backward').removeClass('forward');
    }

    submenu_mobile_backward(e) {

        const $this = $(e.currentTarget);
        

        const submenu = $this.closest('ul');
        const backward = {
            current: submenu.parent(),
            siblings: submenu.parent().siblings(),
        }

        // self.submenu.removeClass('active');
        submenu.removeClass('active').hide();        

        backward.current.addClass('forward').removeClass('backward');
        backward.siblings.addClass('forward').removeClass('backward');
    }

    submenu_mobile_reset() {

        const self = this;

        $('body').removeClass('mobile-menu-is-open');
        $(self.trigger.burger).removeClass('is-open').removeClass('is-closed');
        self.remove_active_class(self.items);
        self.remove_active_class(self.submenu);
        self.hide_all_submenu();
        self.items.removeClass('backward');
    }

    submenu_mobile_close() {

        const self = this;

        $('body').removeClass('mobile-menu-is-open');
        $(self.trigger.burger).removeClass('is-open').removeClass('is-closed');
    }

    // ajoute un item "retour" aux .sub-menu
    add_menu_back_trigger() {

        const self = this;
        const label = self.nav.attr('data-menu-back-label');

        if (self.submenu.length === 0) return false;

        self.submenu.each((i, submenu) => {

            $(submenu).prepend(
                `<li class="menu-item menu-back">
                    <button data-js-menu-back-trigger="true">
                        ${label}
                    </button>
                </li>`
            );
        });
    }

    // ajoute un button aux .menu-item-has-children
    add_submenu_trigger() {

        const self = this;

        if (self.submenu.length === 0) return false;

        self.submenu.each((i, submenu) => {

            $(submenu).before(`
                <button class="menu-item-dropdown" data-js-submenu-trigger="true">
                    <i class="icon-arrow-angle-right"></i>
                </button>
            `);
        });
    }

    add_active_class(el) {
        el.addClass('active').show();
    }

    remove_active_class(el) {
        el.removeClass('active');
    }

    active_ancestor_item(e) {

        const self = this;
        const item = $(e.currentTarget);
        const ancestors = item.parents('li');

        self.remove_active_class(self.items);
        self.add_active_class(ancestors);
    }

    set_submenu_top_offset() {

        const self = this;

        if ($(window).width() >= breakpoint.desktop) {
            
            let offset = {
                reference: self.menu.offset(),
            };

            offset.total = offset.reference.top + self.menu.innerHeight();

            self.submenu.each((i, submenu) => {
                offset.parent = $(submenu).parent().offset();
                $(submenu).css('top', -offset.parent.top + offset.total + 'px');
            });

            console.log(offset.reference);
        }
        else {
            self.submenu.css('top', '0px');
        }
    }

    set_data_submenu_height(item) {

        item = (item instanceof jQuery) ? item : $(item);

        const submenu = {
            child: item.children('.sub-menu'),
            ancestors: item.parents('.sub-menu'),
        };

        const height = {};

        height.ancestors = [];
        height.submenu = (submenu.child.length === 0) ? 0 : Math.round(submenu.child.innerHeight());

        submenu.ancestors.each((i, submenu) => height.ancestors.push(Math.round($(submenu).innerHeight())));

        height.parent = (submenu.ancestors.length === 0) ? 0 : get_max_value(height.ancestors);
        height.final = height.parent >= height.submenu ? height.parent : height.submenu;

        item.attr('data-submenu-height', height.final);

    }

    add_data_submenu_height() {

        const self = this;

        $(self.items).each((i, item) => self.set_data_submenu_height(item));
    }

    expand_background_menu() {

        const self = this;

        self.background.attr('data-megamenu-background', 'expand');
    }

    reduce_background_menu() {

        const self = this;

        self.background.attr('data-megamenu-background', 'collapse')
    }

    update_background_height(e) {

        const self = this;

        let value = Number($(e.currentTarget).parent().attr('data-submenu-height'));

        self.background.css('height', value);

        if (value != 0) self.expand_background_menu()
        else self.reduce_background_menu();
    }

    hide_all_submenu() {
        const self = this;
        self.submenu.hide();
    }

    display_all_submenu() {
        const self = this;
        self.submenu.show();
    }

    add_submenu_title() {

        const self = this;

        if (self.submenu.length === 0) return false;

        self.submenu.each((i, submenu) => {

            var parent = submenu.parentElement;
            var title = parent.querySelector('a');            

            $(submenu).prepend(
                `<li class="menu-title">
                    <h5>
                        ${title.textContent}
                    </h5>
                </li>`
            );
        });
    }
}

export default (new MegaMenu());