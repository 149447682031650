import { adminbar } from '../utils/variables';

class Header {

    constructor() {

        this.sticky = {
            bodyclass: 'header-is-sticky',
        };
    }

    init() {

    }

    set_sticky_class() {

        const self = this;

        if (self.is_sticky()) self.add_sticky_class();
        else self.remove_sticky_class();
    }

    is_sticky() {

        var st = $(window).scrollTop();

        // return a boolean
        return st > adminbar.height;
    }

    add_sticky_class() {
        const self = this;
        $('body').addClass(self.sticky.bodyclass)
    }

    remove_sticky_class() {
        const self = this;
        $('body').removeClass(self.sticky.bodyclass)
    }
}

export default (new Header);