import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollSmoother } from 'gsap/ScrollSmoother';
import { SplitText } from 'gsap/SplitText';

class Animations {


    init() {
        gsap.registerPlugin(ScrollTrigger, SplitText, ScrollSmoother);

        const self = this;
        self.scroll();
        self.parallax();
        // self.bgcolor();
    }

    scroll() {
        
        if(!window.matchMedia('(pointer: coarse)').matches && window.innerWidth > 1200) {
            const smoother = ScrollSmoother.create({
                wrapper: '#smooth-wrapper',
                content: '#smooth-content',
                smooth: 0.5,
                normalizeScroll: false, //Bug returns when this is set to true
                ignoreMobileResize: true,
                effects: true,
                preventDefault: true,
            });

            smoother.scrollTop(0);
        }

        // intro
        let split = new SplitText('.page-title', {type:'lines, chars, words'})
        gsap.from(split.chars, 0.7, {
            y: -15, 
            duration: 0,
            opacity: 0,
            scale: 0,
            rotationX: 0,
            transformOrigin: '0% 50% -50',
            ease: 'circ.inOut',
            force3D:true,
            stagger:{
                each:0.015,
            },
        }, 0)
        // end intro
        
        // scroll up and down + header
        let lastScrollTop = 0;
  
            window.addEventListener('scroll', () => {
                let top = window.scrollY;
                let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    
                if(top >  150) {
                    document.querySelector('header').classList.add('is-scrolled');
                } else {
                    document.querySelector('header').classList.remove('is-scrolled');
                }
                if(window.innerWidth > 1200){
                    if (scrollTop > lastScrollTop) {
                        document.querySelector('header').classList.add('is-off');
                    } else {
                        document.querySelector('header').classList.remove('is-off');
                    }
                }
    
                lastScrollTop = scrollTop;
            })
        
  

         
        
        if(window.innerWidth > 1200) {
             // slider module animation
            
             document.querySelectorAll('.animated-title').forEach((els) => {
                let top = els.getBoundingClientRect().top;
                console.log(top);
                if(top > window.innerHeight / 2) {
                    gsap.utils.toArray(els).forEach(title => {
                        let split = new SplitText(title, {type:'chars, words'})
                        gsap.from(split.chars, 0.3, {
                            y: -15, 
                            duration: 0,
                            opacity: 0,
                            scale: 0,
                            rotationX: 0,
                            transformOrigin: '0% 50% -50',
                            ease: 'circ.inOut',
                            force3D:true,
                            stagger:{
                                each:0.015,
                            },
                            scrollTrigger: {
                                trigger: title,
                                start: `top ${window.innerHeight / 0.7}`,
                                scroller: '#smooth-wrapper',
                                toggleActions: 'play none none none',
                                // scrub: true,
                            },
                        }, 0)
                    })
                }
            })


            gsap.utils.toArray('.animated-text').forEach(title => {
                // let split = new SplitText(title, {type:'lines, chars, words'})
                gsap.from(title, 0.5, {
                    y: -35, 
                    opacity: 0,
                    scale: 0.5,
                    rotationX: 0,
                    transformOrigin: '0% 50% -50',
                    ease: 'circ.inOut',
                    force3D:true,
                    scrollTrigger: {
                        trigger: title,
                        start: `top ${window.innerHeight / 0.7}`,
                        scroller: '#smooth-wrapper',
                        toggleActions: 'play none none none',
                        // scrub: true,
                    },
                }, 0)
            })

             // row grid module animation
             gsap.utils.toArray('.text-image-module').forEach(grid => {
                gsap.utils.toArray(grid.querySelectorAll('.animated-block')).forEach((kpi) => {
                    gsap.from(kpi, {
                        scrollTrigger: {
                            trigger: grid,
                            start: 'top 50%',
                            toggleActions: 'play none none none',
                        },
                        autoAlpha: 0,
                        duration: 0.5, 
                        rotation: 0,
                        scale: 0.7,
                        y:50, ease: 'circ.out', 
                    })
                })
            })
          
            // const process = document.querySelectorAll('.slider-module');

            // if (typeof(process) !== 'undefined' && process !== null) {
            //     process.forEach((els) => {
            //         let sections = els.querySelectorAll('.swiper-slide');
                
            //         // Get the width of the container that includes all slides
            //         const wrapperWidth = els.querySelector('.swiper-wrapper').scrollWidth;
                    
            //         // Calculate the total scroll distance based on the width of the wrapper
            //         const scrollDistance = wrapperWidth - els.clientWidth ;
        
            //         // Create the horizontal scroll animation
            //         gsap.to(sections, {
            //             xPercent: -90 * (sections.length - 1),
            //             ease: 'none',
            //             // ease: 'circ.out',
            //             scrollTrigger: {
            //                 trigger: els,
            //                 start: 'top top',
            //                 end: () => '+=' + scrollDistance, 
            //                 scrub: 1, 
            //                 pin: true,   
            //                 pinSpacing: true, 
            //                 anticipatePin: 1,
            //             },
            //         });
            //     })
            
            // }

             // annuaire grid module animation
            gsap.utils.toArray('.annuaire-module').forEach(grid => {
                gsap.utils.toArray(grid.querySelectorAll('.block')).forEach((kpi, i) => {
                    gsap.from(kpi, {
                        scrollTrigger: {
                            trigger: grid,
                            start: 'top 50%',
                            toggleActions: 'play none none none',
                        },
                        autoAlpha: 0,
                        duration: 0.5, 
                        rotation: 0,
                        scale: 0.7,
                        y:50, ease: 'circ.out', 
                        delay: i * 0.0715,
                    })
                })
            })

             // annuaire grid module animation
             gsap.utils.toArray('.partners-module').forEach(grid => {
                gsap.utils.toArray(grid.querySelectorAll('.block')).forEach((kpi, i) => {
                    gsap.from(kpi, {
                        scrollTrigger: {
                            trigger: grid,
                            start: 'top 50%',
                            toggleActions: 'play none none none',
                        },
                        autoAlpha: 0,
                        duration: 0.5, 
                        rotation: 0,
                        scale: 0.7,
                        y:50, ease: 'circ.out', 
                        delay: i * 0.715,
                    })
                })
            })

             // row grid module animation
             gsap.utils.toArray('.documents-module').forEach(grid => {
                gsap.utils.toArray(grid.querySelectorAll('.row')).forEach((kpi, i) => {
                    gsap.from(kpi, {
                        scrollTrigger: {
                            trigger: grid,
                            start: 'top 50%',
                            toggleActions: 'play none none none',
                        },
                        autoAlpha: 0,
                        duration: 0.5, 
                        rotation: 0,
                        scale: 0.7,
                        y:50, ease: 'circ.out', 
                        delay: i * 0.0715,
                    })
                })
            })

             // row grid module animation
             gsap.utils.toArray('.trombinoscope-module').forEach(grid => {
                gsap.utils.toArray(grid.querySelectorAll('.row')).forEach((kpi, i) => {
                    gsap.from(kpi, {
                        scrollTrigger: {
                            trigger: grid,
                            start: 'top 50%',
                            toggleActions: 'play none none none',
                        },
                        autoAlpha: 0,
                        duration: 0.5, 
                        rotation: 0,
                        scale: 0.7,
                        y:50, ease: 'circ.out', 
                        delay: i * 0.0715,
                    })
                })
            })


             // row grid module animation
             gsap.utils.toArray('.text-image-menu').forEach(grid => {
                gsap.utils.toArray(grid.querySelectorAll('li')).forEach((kpi, i) => {
                    gsap.from(kpi, {
                        scrollTrigger: {
                            trigger: grid,
                            start: 'top 50%',
                            toggleActions: 'play none none none',
                        },
                        autoAlpha: 0,
                        duration: 0.5, 
                        rotation: 0,
                        scale: 0.7,
                        y:50, ease: 'circ.out', 
                        delay: i * 0.0715,
                    })
                })
            })

            // row grid module animation
            gsap.utils.toArray('.text-image-module').forEach(grid => {
                gsap.utils.toArray(grid.querySelectorAll('.img-wrapper figure')).forEach((kpi) => {
                    gsap.from(kpi, {
                        scrollTrigger: {
                            trigger: grid,
                            start: 'top 50%',
                            toggleActions: 'play none none none',
                        },
                        autoAlpha: 0,
                        duration: 0.5, 
                        rotation: 0,
                        scale: 0.7,
                        y:50, ease: 'circ.out', 
                    })
                })
            })

            // row grid module animation
            gsap.utils.toArray('.triptyque-module').forEach(grid => {
                gsap.utils.toArray(grid.querySelectorAll('.block picture')).forEach((kpi) => {
                    gsap.from(kpi, {
                        scrollTrigger: {
                            trigger: grid,
                            start: 'top 50%',
                            toggleActions: 'play none none none',
                        },
                        autoAlpha: 0,
                        duration: 0.5, 
                        rotation: 0,
                        scale: 0.7,
                        y:50, ease: 'circ.out', 
                        stagger:{
                            each:0.015,
                        },
                    })
                })
            })

            // row grid module animation
            gsap.utils.toArray('.partners-module').forEach(grid => {
                gsap.utils.toArray(grid.querySelectorAll('.grid picture')).forEach((kpi) => {
                    gsap.from(kpi, {
                        scrollTrigger: {
                            trigger: grid,
                            start: 'top 50%',
                            toggleActions: 'play none none none',
                        },
                        autoAlpha: 0,
                        duration: 0.5, 
                        rotation: 0,
                        scale: 0.7,
                        y:50, ease: 'circ.out', 
                        stagger:{
                            each:0.015,
                        },
                    })
                })
            })

                // row grid module animation
                gsap.utils.toArray('.wysiwyg').forEach(grid => {
                    gsap.utils.toArray(grid.querySelectorAll('.img-wrapper figure')).forEach((kpi) => {
                        gsap.from(kpi, {
                            scrollTrigger: {
                                trigger: grid,
                                start: 'top 50%',
                                toggleActions: 'play none none none',
                            },
                            autoAlpha: 0,
                            duration: 0.5, 
                            rotation: 0,
                            scale: 0.7,
                            y:50, ease: 'circ.out', 
                        })
                    })
                })

             // row grid module animation
             gsap.utils.toArray('.text-image-module').forEach(grid => {
                gsap.utils.toArray(grid.querySelectorAll('.img-wrapper figure img')).forEach((kpi) => {
                    gsap.from(kpi, {
                        scrollTrigger: {
                            trigger: grid,
                            start: 'top 50%',
                            toggleActions: 'play none none none',
                        },
                        autoAlpha: 0,
                        duration: 2, 
                        rotation: 0,
                        scale: 0.7,
                        y:50, ease: 'circ.out', 
                    })
                })
            })
        }
        
    }

    bgcolor() {
        function querySelectorArray(query, root){
            return Array.prototype.slice.call((root || document).querySelectorAll(query));
        }

        const elements = querySelectorArray('[data-scroll]'),
        bg = document.querySelector('#smooth-wrapper');

        bg.classList.add('none');

        let lastScrollTop = window.pageYOffset || document.documentElement.scrollTop;

        elements.forEach(function(color){
            var group = color.getAttribute('data-scroll'),
                sections = querySelectorArray('[data-' + group + ']');

            window.addEventListener('scroll', function(){
                var currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
                var scrollDown = currentScrollTop > lastScrollTop;
                var lastSection = false;
                

                sections.forEach(function(section){
                    var offset = section.getBoundingClientRect();

                    if (scrollDown) {
                        if (offset.top - (window.innerHeight / 2) < 0 && offset.top > -offset.height) {
                            lastSection = section.getAttribute('data-' + group);
                        }
                    } else {
                        if (offset.bottom - (window.innerHeight / 2) < 0 && offset.bottom > -offset.height) {
                            lastSection = section.getAttribute('data-' + group);
                        }
                    }
                });
             
                
                if(window.scrollY <= 2) {
                    bg.classList.add('none');
                }else {
                    bg.className = lastSection || '';
                }
                lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop; // For Mobile or negative scrolling
            });
        });
    }
    
    parallax() {
        const item = document.querySelector('.scene');


        if (document.querySelector('.scene')) {
            const effect = document.querySelectorAll('.scene .txt');
            const rect = item.getBoundingClientRect();

            const randomMin = gsap.utils.random(-3, -10, 1, true);
            const randomPlus = gsap.utils.random(3, 10, 1, true);
            const width = rect.width;

            const arraySetters = [];

            effect.forEach((item) => {
                const setter = {
                    x: gsap.quickSetter(item, 'x', 'px'),
                    y: gsap.quickSetter(item, 'y', 'px'),
                    minus: gsap.utils.mapRange(0, width, randomMin(), randomPlus()),
                    plus: gsap.utils.mapRange(0, width, randomMin(), randomPlus()),
                };
                arraySetters.push(setter)
            })

            document.querySelector('body').addEventListener('mousemove', (e) => {
                arraySetters.forEach((item, index) => {
                    const obj = arraySetters[index];
                    obj.x(obj.minus(e.clientX - rect.left))
                    obj.y(obj.plus(e.clientY - rect.top))
                });
            });
        }
    }
}

export default (new Animations);
    