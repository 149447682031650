import { gsap } from 'gsap';

class PageLoader {
    constructor() {
        this.html = document.documentElement;
        this.pageloader = document.getElementById('page-loader');
        this.logo = null; // déterminer le logo (s'il y en un)
    }

    init() {
        if (!this.pageloader) return false;

        if( !this.logo )
            this.unreveal_pageheader( () => {
                this.trigger_complete_event();
            });
        else
            this.unreveal_logo( () => {
                this.unreveal_pageheader( () => {
                    this.trigger_complete_event();
                })
            })
    }

    unreveal_logo(callback) {
        gsap.to(this.logo.children, {
            opacity: 0,
            duration: 0.3,
            delay: 0.2,
            ease: 'power2.inOut',
            stagger: {
                each: 0.05,
                from: 'random',
            },
            onComplete: callback,
        });
    }

    unreveal_pageheader(callback) {
        this.pageloader.style.transformOrigin = 'top';
        gsap.to(this.pageloader, {
            scaleY: 0,
            duration: 0.5,
            ease: 'power1.inOut',
            onComplete: callback,
        });
    }

    trigger_complete_event() {
        $(window).trigger('complete');
    }
}

export default new PageLoader();