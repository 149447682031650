// import external dependencies
import 'jquery';
import 'jquery.easing';
import 'what-input/dist/what-input.min.js';
import 'simplebar';
import ResizeObserver from 'resize-observer-polyfill';

window.ResizeObserver = ResizeObserver;

// Import everything from autoload


// import local dependencies
// import { PageLoader } from './layouts/page-loader';
import Router from './utils/Router';
import common from './routes/common';

/** Populate Router instance with DOM routes */
const routes = new Router({
    common,
});

var scrollDebounce;

var resize = {
    timer: false,
    delay: 100,
};

// var iO = 'IntersectionObserver' in window;

// Load Events
jQuery(document).ready( () => routes.readyEvents() );

jQuery(window)
  .on('load', () => routes.loadEvents() )
  .on('complete', () => routes.completeEvents() )
  .on('scroll', () => {
    routes.scrollEvents();

    if (scrollDebounce) window.clearTimeout(scrollDebounce)
    scrollDebounce = window.setTimeout(() => routes.scrollEndEvents(), 100);
  })
  .on('resize', () => {
    routes.resizeEvents();

    if (!resize.timer) $(window).trigger('resizestart');

    clearTimeout(resize.timer);

    resize.timer = setTimeout(function () {
      
      resize.timer = false;
      $(window).trigger('resizeend');

    }, resize.delay);
    
  })
  .on('resizestart', () => routes.resizeStartEvents())
  .on('resizeend', () => routes.resizeEndEvents());