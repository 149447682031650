import Swiper, { Navigation, Pagination, EffectFade, EffectCreative, Autoplay, Scrollbar } from 'swiper';
// import { time } from '../variables';

Swiper.use([Navigation, Pagination, EffectFade, Autoplay, EffectCreative, Scrollbar]);

class CommonSlideshow {


    init() {

        // if(window.innerWidth <= 1200) {
            new Swiper('.slider-module [data-js-slideshow]', {
                grabCursor: true,
                spaceBetween: 0,
                slidesPerView: 'auto',
                speed: 1000,
                direction: 'horizontal',
                loop: false,
                slidesOffsetAfter: window.innerWidth / 3,
                scrollbar: {
                    el: '.slider-module .swiper-scrollbar',
                    hide: false,
                    dragSize: 100,
                    draggable: true,
                    snapOnRelease: true,
                },
                // navigation: false,
                breakpoints: {
                    1200: {
                        slidesOffsetAfter: window.innerWidth / 7,
                        navigation: {
                            nextEl: '.slider-module .swiper-button-next',
                            prevEl: '.slider-module .swiper-button-prev',
                        },
                    },
                },
            });
        // }

        if(window.innerWidth <= 1200) {
            new Swiper('.triptyque-module [data-js-slideshow]', {
                grabCursor: true,
                spaceBetween: 0,
                slidesPerView: 'auto',
                speed: 1000,
                direction: 'horizontal',
                loop: false,
                slidesOffsetAfter: 120,
            });
        }
    }
}

export default (new CommonSlideshow());