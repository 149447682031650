export default {
    init() {
        const burger = document.querySelector('.burger');
        burger.addEventListener('click', () => {
            if(burger.classList.contains('active')) {
                burger.classList.remove('active');
                document.body.setAttribute('data-mobile-menu-is-open', false);
            }
            else {
                burger.classList.add('active');
                document.body.setAttribute('data-mobile-menu-is-open', true);
            }
        });
    },
}
