import Tools from '../utils/tools';

class KeyFigures {

    constructor() {
        this.attr = 'data-js-kpi-number';
        this.selector = '[' + this.attr + ']';
        this.floatingSeparator = ','; // par défaut, le séprateur des noombres à virgule sera un point

        this.IOSettings = {
            root: null,  // use the viewport
            rootMargin: '0px',
            threshold: 1.0,
            delay: 200,
        };
    }

    init() {

        const self = this;

        if (!document.querySelector(self.selector)) return false;

        const numbers = document.querySelectorAll(self.selector);

        // split les chiffres à virgule, supprime l'attribut du self.selector actuel
        // et wrap ces derniers dans de nouveaux elemnents html avec l'attribut du self.selector
        numbers.forEach(number => {
            self.formatFloatingNumber(number);
        });

        // mise à jour des nouveaux selecteurs css
        numbers.new = document.querySelectorAll(self.selector);

        numbers.new.forEach(number => {
            self.formatNumber(number);
            self.resetNumber(number);
        });

    }

    // Lance l'animation de chaque chiffres clés
    // lorsque ces derniers apparaissent dans le viewport via IntersectionObserver
    lauch() {

        const self = this;

        if (!document.querySelector(self.selector)) return false;

        const io = new IntersectionObserver(self.IOCallback, self.IOSettings);
        const numbers = document.querySelectorAll(self.selector);

        console.log('launch');

        var i = 0;
        numbers.forEach(number => {
            number.index = i;
            io.observe(number, io);
            i++;
        });

    }


    // Initialise le chiffre clé à zéro
    resetNumber(elem) {
        elem.innerText = '0';
    }

    // Callback IntersectioObserver animant les nombres de 0 à leur valeur par défaut
    IOCallback(entries, observer) {

        entries.forEach(entry => {
            var target = Number(entry.target.getAttribute('data-js-kpi-number'));
            var duration = 2000; // milisecond
            var delay = 100; // milisecond

            if (entry.isIntersecting) {

                jQuery({ Counter: 0 })
                    .delay(entry.target.index * delay)
                    .animate({ Counter: target }, {
                        duration: duration,
                        easing: 'swing',
                        step: function () {
                            entry.target.innerText = Math.ceil(this.Counter);
                            entry.target.innerText = Tools.setThousandSeparator(entry.target.innerText);
                        },
                        complete: function () {
                            entry.target.innerText = target;
                            entry.target.innerText = Tools.setThousandSeparator(entry.target.innerText);
                        },
                    });

                observer.unobserve(entry.target);
            }
        });
    }

    // teste si c'est un nombre séparé par un point ou une virgule
    isFloatingNumber(number) {
        var value = number.innerText;
        var regex = /[,|.]/;

        return value.match(regex);
    }

    // split les chiffres à virgule, supprime l'attribut "data-js-kpi-number" de l'élément courant
    // et wrap les valeurs "splitées" dans de nouveaux élémnents html en leur ajoutant l'attribut "data-js-kpi-number"
    // afin de pouvoir les animer ensuite
    formatFloatingNumber(number, separator) {

        if (!this.isFloatingNumber(number)) return;

        separator = separator || this.floatingSeparator;

        var regex = /[,|.]/;
        var value = number.innerText;

        // on supprime le data attribute du nombre
        number.removeAttribute('data-js-kpi-number');
        number.innerText = '';

        // on sépare les valeurs avant et après la virgule ou le point
        number.splited = value.split(regex);

        // console.log('number.splited', number.splited);

        for (let i = 0; i < number.splited.length; i++) {
            const num = number.splited[i];
            // création de la span qui recevra le numéro
            const wrap = document.createElement('span');
            // ajout du data-attrbute permettant d'être utilisé par la suite avec le numéro à atteindre
            wrap.setAttribute('data-js-kpi-number', num);
            wrap.innerText = num;

            number.append(wrap);
            if (i === 0) wrap.after(separator);
        }
    }

    // formate la chaine de caractère du nombre en supprimant les éventuels espaces
    formatNumber(number) {

        if (this.isFloatingNumber(number)) return;

        var value = number.innerText.split(' ');
        number.innerText = value.join('');
        number.setAttribute(this.attr, value.join(''));
    }
}

export default (new KeyFigures);