import $ from 'jquery';

class Trailer {

    constructor() {
        this.video = {
            player: '[data-videoreveal-player]',
            trigger: '[data-videoreveal-trigger]',
            iframe: '[data-videoreveal-iframe]',
            placeholder: '[data-videoreveal-placeholder]',
            config: {
                youtube: '?autoplay=1&controls=1&rel=0&showinfo=0&mute=1', // for Youtube iframe
            },
        }
    }

    
    init() {
        this.setupElements();
        this.setupEventListeners();

    }

    setupElements() {
        // this.cursor = document.querySelector('.cursor'); 
        this.cards = document.querySelectorAll('.page-header .trigger');
        this.sidebar = document.querySelector('.video-content');
        this.backdrop = document.querySelector('.video-backdrop');
        this.closeBtn = document.querySelector('.close-btn');
        this.video = this.sidebar.querySelector('iframe');
        this.image = this.sidebar.querySelector('img');
        this.videoTrigger = this.sidebar.querySelector('.trigger');
        // console.log(this.videoTrigger)
        this.closeBtn = this.sidebar.querySelector('.close-btn');
    }

    setupEventListeners() {
        this.cards.forEach((card) => {
            card.addEventListener('click', () => this.openSidebar(card));
        });

        this.closeBtn.addEventListener('click', () => this.closeSidebar());
        this.backdrop.addEventListener('click', () => this.closeSidebar());
    }

    openSidebar(card) {
      
        this.updateSidebarContent(card);
        this.sidebar.classList.add('is-open');
        // this.cursor.classList.add('is-open');
        // this.header.classList.add('is-up')
        document.body.classList.add('is-close')

        let url =  $(this.videoTrigger).attr('data-videoreveal-src') + '?autoplay=1&controls=1&rel=0&showinfo=0&mute=1';

        if (this.video.length === 0) return false;

        $( this.videoTrigger).fadeOut();
        $( this.video).attr('src', url);
        $(this.video).fadeIn();
        if ($(this.video).is('video')) {
            $(this.video[0]).play();
        }

        if ($(this.image).length === 0) return false;

        $(this.image).fadeOut();
    }

    updateSidebarContent(card) {
        
        this.image.src = document.querySelector('.page-header img').getAttribute('src');
        this.video.src = card.getAttribute('data-video');
        this.videoTrigger.setAttribute('data-videoreveal-src', card.getAttribute('data-video'));
    }

    closeSidebar() {
        this.sidebar.classList.remove('is-open');
        // this.cursor.classList.remove('is-open');
        document.body.classList.remove('is-close')
        // this.header.classList.remove('is-up')

        this.image.src = '';
        this.video.src = '';
    
    }
}

export default new Trailer();