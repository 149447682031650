export const breakpoint = {
    defaut:     0,
    mobile:     500,
    tablet:     700,
    sidebar:    960, // breakpoint où la sidebar se positionne à gauche où à droite (si le site contient une sidebar...)
    desktop:    1200,
    large:      1440,
    wide:       1921,
};

export const time = {
    null: 0,
    xs: 100,
    sm: 200,
    md: 400,
    lg: 800,
    xl: 1200,
    xxl: 2400,
};

export const gsaptime = {
    null: 0,
    xs: time.xs / 1000,
    sm: time.sm / 1000,
    md: time.md / 1000,
    lg: time.lg / 1000,
    xl: time.xl / 1000,
    xxl: time.xxl / 1000,
};

export const header = {
    id: '#header',
    wrapper: '.header-wrapper',
    inner: '.header-inner',
    navigation: '.header-navigation',
    search: '.header-search',
    burger: '.header-burger',
    toolbar: '.header-toolbar',
    flashinfo: '.header-flashinfo',
    adminbar: ( $(window).width() ) > 780 ? 32 : 46,
    height: ( $(window).width() ) >= breakpoint.desktop ? 100 : 60, // mettre à jour si la variable $header-heights du fichier _variables.scss à changer
};

export const adminbar = {
    height: ( $('body').hasClass('admin-bar') ) ? header.adminbar : 0,
}
