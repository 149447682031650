import { adminbar, header, time } from '../utils/variables';
// import objectFitImages from 'object-fit-images';

class Tools {

    // ie11 detection
    detectIe11() {

        if (/MSIE 9/i.test(navigator.userAgent) || /rv:11.0/i.test(navigator.userAgent)) {
            document.body.classList.add('ie11');
        }
    }

    objectFitImages() {
        // objectFitImages();
    }

    // Equal height
    initEqualHeight() {

        if ($('.js-equal-height').length === 0) {
            return false
        }

        $('.js-equal-height').matchHeight({
            byRow: true,
            property: 'min-height',
        });
    }

    scrollDirection() {

        let lastScrollTop = 0;

        $(window).on('scroll', () => {

            let st = $(window).scrollTop();

            // downscroll code
            if (st > lastScrollTop) $('body').removeClass('scrolling-up').addClass('scrolling-down')
            // upscroll code
            else $('body').removeClass('scrolling-down').addClass('scrolling-up');

            lastScrollTop = st;
        });
    }

    /* Smooth scroll */
    smoothScroll(trigger) {

        const self = this;

        trigger = trigger || '[data-smooth-scroll]';
        
        $(document).on('click', trigger, (e) => {
            self.setSmoothScroll(e);
        });
    }

    setSmoothScroll(e) {

        e.preventDefault();

        let href =  $(e.currentTarget).attr('href');

        if ( $(href).length === 0 ) return false;

        const offset = {
            gap: 40, // laisser de l'espace vide
            header: Math.round( $(header.wrapper).height() ),
            target: $(href).offset().top,
            adminbar: adminbar.height,
        };

        $('html,body').stop().animate({
            scrollTop: offset.target - offset.header - offset.adminbar - offset.gap,
        }, time.lg, 'easeInOutQuart');

        return false;
    }

    /* @param {Object} - Swiper instance only !! */
    addSwiperClassDirections(swiper) {

        if (swiper.$el.length === 0) return false;

        const way = {
            forward: 'on-forward',
            back: 'on-back',
        };

        swiper
            .on('slideNextTransitionStart', (swiper) => swiper.$el.addClass(way.forward).removeClass(way.back))
            .on('slidePrevTransitionStart', (swiper) => swiper.$el.addClass(way.back).removeClass(way.forward))
    }

    // Fonction pour créer la séparation des milliers
    setThousandSeparator(number, thousandSep) {
        // par défaut le séparateur des milliers sera un espace insécable
        thousandSep = thousandSep || ' ';

        var parts = number.toString().split(/[,|.]/);
        
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandSep);

        return parts;
    }
}

export default (new Tools);