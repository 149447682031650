export class Modal {

    /*
    ** @param 1 {string} optionnel - css selector d'un autre element que [data-toggle="modal"]
    ** @param 2 {string} optionnel - css selector de la modal à afficher
    */
    constructor(trigger, target) {
        this.modal = document.getElementById('modal');
        this.content = this.modal.querySelectorAll('.modal-content');
        this.target = document.querySelector(target);
        this.trigger = {
            open: trigger || '[data-toggle="modal"]',
            close: '[data-close="modal"]',
        };
    }

    init() {

        const self = this;

        if (!self.modal) return false;

        if(self.content) self.close();
        if(self.target) self.close();

        $(document)
            .on('click', self.trigger.open, (e) => self.open(e.currentTarget))
            .on('click', self.trigger.close, () => self.close())
            .on('keydown', (e) => {
                if (e.key == 'Escape') self.close();
            });

    }

    open(el) {

        const self = this;
        const data = {
            target: el.getAttribute('data-target'),
        };
        const target = data.target || self.target;

        $(self.modal).fadeIn();

        if (!target) return false;
        $(target).show();
    }

    close() {

        const self = this;

        $(self.modal).fadeOut();

        if (self.target) $(self.target).fadeOut();

        if (!self.content) return false;
        self.content.forEach(content => content.style.display = 'none');
    }
}
