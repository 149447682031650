class Lang {

    constructor() {
        this.trigger  = '[data-lang-trigger]'
        this.langcontainer  = '[data-lang-container]';

        this.isOpen = false;
    }

    init() {

        const self = this;
        if(window.innerWidth > 1500) {
            $(self.trigger).on('mouseenter', () => {
                if ($(self.langcontainer).length == 0) {
                    return;
                }
                
                $(self.langcontainer).toggleClass('is-active');
                $(self.trigger).toggleClass('is-active');
    
                // $('.search-container').removeClass('is-open')
                // $('.header-search').removeClass('is-open')
    
                // document.querySelector('.access-trigger').classList.remove('is-active');
                // document.querySelector('.pojo-a11y-toolbar-right').classList.remove('pojo-a11y-toolbar-open')
            })
        } else {
            $(self.trigger).on('click', () => {
                if ($(self.langcontainer).length == 0) {
                    return;
                }
                
                $(self.langcontainer).toggleClass('is-active');
                $(self.trigger).toggleClass('is-active');
    
                // $('.search-container').removeClass('is-open')
                // $('.header-search').removeClass('is-open')
    
                // document.querySelector('.access-trigger').classList.remove('is-active');
                // document.querySelector('.pojo-a11y-toolbar-right').classList.remove('pojo-a11y-toolbar-open')
            })
        }
       

        const langs = document.querySelectorAll('.lang-container span');
        langs.forEach((els) => {
            els.addEventListener('click', () => {
                // let txt = els.innerText;
                // const txtContainer = document.querySelector('.lang span')
                // console.log(txt)
                // txtContainer.innerText = txt;

                $(self.langcontainer).removeClass('is-active');
                $(self.trigger).removeClass('is-active');
            })
            // els.addEventListener('mouseleave', () => {
            //     $(self.langcontainer).removeClass('is-active');
            //     $(self.trigger).removeClass('is-active');
            // })
        })

    }
}

export default (new Lang);
