import { adminbar, header } from '../utils/variables';

class Accordion {

    constructor() {
        this.accordion  = '[data-accordion]';
        this.item       = '[data-accordion-item]';
        this.content    = '[data-accordion-content]';
        this.trigger    = '[data-accordion-trigger]';

        this.hasBeenClicked = false;
    }

    init() {

        const self = this;

        if (
            $(self.accordion).length === 0 &&
            $(self.trigger).length === 0 &&
            $(self.content).length === 0
        ) return false;
        
        $(document).on('click keypress', self.trigger, (e) => {
            
            self.hasBeenClicked = true;

            let isOpen = e.currentTarget.getAttribute('aria-expanded') === 'true';
            // console.log('isOpen', isOpen);
            
            if(!isOpen) self.open(e.currentTarget)
            else self.close(e.currentTarget)
        });

        $(self.trigger).each((i, trigger) => {
            self.checkAriaExpanded(trigger)
        } );

    }

    getConfig(trigger) {

        const self = this;
        const $accordion = $(trigger).closest(self.accordion);

        return {
            stillOpen: $accordion.attr('data-still-open') === undefined ? true : $accordion.attr('data-still-open') === 'true',
            autoScroll: $accordion.attr('data-auto-scroll') === 'true',
            offset: $(trigger).offset(),
        }
    }

    checkAriaExpanded(trigger) {
        
        const self = this;
        let ariaIsExpanded = trigger.getAttribute('aria-expanded') === 'true';

        if( !ariaIsExpanded ) self.close(trigger);
        else self.open(trigger);
    }

    close(trigger) {
        const self = this;

        $(trigger).parent().removeClass('is-open');
        $(trigger).next(self.content).slideUp( 300, () => self.autoScroll(trigger) );
        $(trigger).attr('aria-expanded', false);
    }

    closeSiblings(trigger, hasBeenClicked) {
        
        const self = this;
        hasBeenClicked = self.hasBeenClicked;

        const siblings = {
            trigger: $(trigger).parent().siblings().children(self.trigger),
        };

        if(hasBeenClicked) self.close(siblings.trigger);
    }

    open(trigger) {
        
        const self = this;
        const config = self.getConfig(trigger);

        $(trigger).parent().addClass('is-open');
        $(trigger).next(self.content).slideDown( 300, () => self.autoScroll(trigger) );
        $(trigger).attr('aria-expanded', true);

        // console.log('config', config);

        if(!config.stillOpen) self.closeSiblings(trigger);
    }

    autoScroll(trigger) {

        const self = this;

        if(!self.hasBeenClicked) return false;

        var config = self.getConfig(trigger);

        // espace entre le haut de l'écran et l'item
        // peut être égal à la hauteur du header par exemple moins l'admin-bar (si elle est présente)
        var offset = Math.round(config.offset.top - header.height - adminbar.height - 60); // 40 === gap entre le header et l'item

        if( !config.autoScroll ) return false;
        $('html, body').stop().animate({ scrollTop: offset }, 1500, 'easeInOutQuart');
    }
}

export default (new Accordion);
