class Video {

    constructor() {
        this.video = {
            player: '[data-videoreveal-player]',
            trigger: '[data-videoreveal-trigger]',
            iframe: '[data-videoreveal-iframe]',
            placeholder: '[data-videoreveal-placeholder]',
            config: {
                youtube: '?autoplay=1&controls=1&rel=0&showinfo=0', // for Youtube iframe
            },
        }
    }

    reveal() {
        const self = this;

        $(document).on('click', self.video.trigger, (e) => {

            e.preventDefault();

            const $trigger = $(e.currentTarget);
            const $player = $trigger.closest(self.video.player);
            const $iframe = $player.find(self.video.iframe);
            const $placeholder = $player.find(self.video.placeholder);

            let url = $trigger.attr('data-videoreveal-src') + self.video.config.youtube;

            if ($iframe.length === 0) return false;

            $trigger.fadeOut();
            $iframe.attr('src', url);
            
            if ($iframe.is('video')) {
                $iframe[0].play();
            }

            if ($placeholder.length === 0) return false;

            $placeholder.fadeOut();
        });
    }
    
}

export default (new Video());